/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';

/**
 * 
 * @export
 * @enum {string}
 */
export type ChargeBasis =
  'Daily' | 
  'Weekly' | 
  'Monthly' | 
  'Quarterly' | 
  'SemiAnnual' | 
  'Annual'




export function ChargeBasisFromJSON(json: any): ChargeBasis {
    return ChargeBasisFromJSONTyped(json, false);
}

export function ChargeBasisFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChargeBasis {
    return json;
}

export function ChargeBasisToJSON(value?: ChargeBasis | null): any {
    return value;
}

