/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';

/**
 * 
 * @export
 * @interface BillingItem
 */
export interface BillingItem {
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    acId: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    acName: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    bcId: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    bcName: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    biBegin: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    biDate: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    biEnd: string;
    /**
     * 
     * @type {boolean}
     * @memberof BillingItem
     */
    biIncTax: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BillingItem
     */
    biIsReversal: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BillingItem
     */
    biIsReversed: boolean;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    biPeriodBegin: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    biPeriodEnd: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    biRef: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    biSeq: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    biStatus: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    biType: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    blCode: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    blId: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    blName: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    buCode: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    buId: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    buName: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    chId: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    chType: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    entity: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    itCode: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    itId: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    itName: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    lcId: string;
    /**
     * 
     * @type {number}
     * @memberof BillingItem
     */
    liAmount: number;
    /**
     * 
     * @type {number}
     * @memberof BillingItem
     */
    liTax: number;
    /**
     * 
     * @type {number}
     * @memberof BillingItem
     */
    liTotal: number;
    /**
     * 
     * @type {number}
     * @memberof BillingItem
     */
    liUnitAmt: number;
    /**
     * 
     * @type {number}
     * @memberof BillingItem
     */
    liUnits: number;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    ocCode: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    ocId: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    ocName: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    shortCode: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    spCode: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    spId: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    spName: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    sy1Id: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    sy1Key: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    taCode: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    taId: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    taName: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    upId: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    upName: string;
    /**
     * 
     * @type {number}
     * @memberof BillingItem
     */
    upTimestamp: number;
}


/**
 * Check if a given object implements the BillingItem interface.
 */
export function instanceOfBillingItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "acId" in value;
    isInstance = isInstance && "acName" in value;
    isInstance = isInstance && "bcId" in value;
    isInstance = isInstance && "bcName" in value;
    isInstance = isInstance && "biBegin" in value;
    isInstance = isInstance && "biDate" in value;
    isInstance = isInstance && "biEnd" in value;
    isInstance = isInstance && "biIncTax" in value;
    isInstance = isInstance && "biIsReversal" in value;
    isInstance = isInstance && "biIsReversed" in value;
    isInstance = isInstance && "biPeriodBegin" in value;
    isInstance = isInstance && "biPeriodEnd" in value;
    isInstance = isInstance && "biRef" in value;
    isInstance = isInstance && "biSeq" in value;
    isInstance = isInstance && "biStatus" in value;
    isInstance = isInstance && "biType" in value;
    isInstance = isInstance && "blCode" in value;
    isInstance = isInstance && "blId" in value;
    isInstance = isInstance && "blName" in value;
    isInstance = isInstance && "buCode" in value;
    isInstance = isInstance && "buId" in value;
    isInstance = isInstance && "buName" in value;
    isInstance = isInstance && "chId" in value;
    isInstance = isInstance && "chType" in value;
    isInstance = isInstance && "entity" in value;
    isInstance = isInstance && "itCode" in value;
    isInstance = isInstance && "itId" in value;
    isInstance = isInstance && "itName" in value;
    isInstance = isInstance && "lcId" in value;
    isInstance = isInstance && "liAmount" in value;
    isInstance = isInstance && "liTax" in value;
    isInstance = isInstance && "liTotal" in value;
    isInstance = isInstance && "liUnitAmt" in value;
    isInstance = isInstance && "liUnits" in value;
    isInstance = isInstance && "ocCode" in value;
    isInstance = isInstance && "ocId" in value;
    isInstance = isInstance && "ocName" in value;
    isInstance = isInstance && "shortCode" in value;
    isInstance = isInstance && "spCode" in value;
    isInstance = isInstance && "spId" in value;
    isInstance = isInstance && "spName" in value;
    isInstance = isInstance && "sy1Id" in value;
    isInstance = isInstance && "sy1Key" in value;
    isInstance = isInstance && "taCode" in value;
    isInstance = isInstance && "taId" in value;
    isInstance = isInstance && "taName" in value;
    isInstance = isInstance && "upId" in value;
    isInstance = isInstance && "upName" in value;
    isInstance = isInstance && "upTimestamp" in value;
    return isInstance;
}

export function BillingItemFromJSON(json: any): BillingItem {
    return BillingItemFromJSONTyped(json, false);
}

export function BillingItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'key': json['key'],
        'id': json['id'],
        'acId': json['acId'],
        'acName': json['acName'],
        'bcId': json['bcId'],
        'bcName': json['bcName'],
        'biBegin': json['biBegin'],
        'biDate': json['biDate'],
        'biEnd': json['biEnd'],
        'biIncTax': json['biIncTax'],
        'biIsReversal': json['biIsReversal'],
        'biIsReversed': json['biIsReversed'],
        'biPeriodBegin': json['biPeriodBegin'],
        'biPeriodEnd': json['biPeriodEnd'],
        'biRef': json['biRef'],
        'biSeq': json['biSeq'],
        'biStatus': json['biStatus'],
        'biType': json['biType'],
        'blCode': json['blCode'],
        'blId': json['blId'],
        'blName': json['blName'],
        'buCode': json['buCode'],
        'buId': json['buId'],
        'buName': json['buName'],
        'chId': json['chId'],
        'chType': json['chType'],
        'entity': json['entity'],
        'itCode': json['itCode'],
        'itId': json['itId'],
        'itName': json['itName'],
        'lcId': json['lcId'],
        'liAmount': json['liAmount'],
        'liTax': json['liTax'],
        'liTotal': json['liTotal'],
        'liUnitAmt': json['liUnitAmt'],
        'liUnits': json['liUnits'],
        'ocCode': json['ocCode'],
        'ocId': json['ocId'],
        'ocName': json['ocName'],
        'shortCode': json['shortCode'],
        'spCode': json['spCode'],
        'spId': json['spId'],
        'spName': json['spName'],
        'sy1Id': json['sy1Id'],
        'sy1Key': json['sy1Key'],
        'taCode': json['taCode'],
        'taId': json['taId'],
        'taName': json['taName'],
        'upId': json['upId'],
        'upName': json['upName'],
        'upTimestamp': json['upTimestamp'],
    };
}

export function BillingItemToJSON(value?: BillingItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'key': value.key,
        'id': value.id,
        'acId': value.acId,
        'acName': value.acName,
        'bcId': value.bcId,
        'bcName': value.bcName,
        'biBegin': value.biBegin,
        'biDate': value.biDate,
        'biEnd': value.biEnd,
        'biIncTax': value.biIncTax,
        'biIsReversal': value.biIsReversal,
        'biIsReversed': value.biIsReversed,
        'biPeriodBegin': value.biPeriodBegin,
        'biPeriodEnd': value.biPeriodEnd,
        'biRef': value.biRef,
        'biSeq': value.biSeq,
        'biStatus': value.biStatus,
        'biType': value.biType,
        'blCode': value.blCode,
        'blId': value.blId,
        'blName': value.blName,
        'buCode': value.buCode,
        'buId': value.buId,
        'buName': value.buName,
        'chId': value.chId,
        'chType': value.chType,
        'entity': value.entity,
        'itCode': value.itCode,
        'itId': value.itId,
        'itName': value.itName,
        'lcId': value.lcId,
        'liAmount': value.liAmount,
        'liTax': value.liTax,
        'liTotal': value.liTotal,
        'liUnitAmt': value.liUnitAmt,
        'liUnits': value.liUnits,
        'ocCode': value.ocCode,
        'ocId': value.ocId,
        'ocName': value.ocName,
        'shortCode': value.shortCode,
        'spCode': value.spCode,
        'spId': value.spId,
        'spName': value.spName,
        'sy1Id': value.sy1Id,
        'sy1Key': value.sy1Key,
        'taCode': value.taCode,
        'taId': value.taId,
        'taName': value.taName,
        'upId': value.upId,
        'upName': value.upName,
        'upTimestamp': value.upTimestamp,
    };
}

