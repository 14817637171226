/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';
import type { ChargeBasis } from './ChargeBasis';
import {
    ChargeBasisFromJSON,
    ChargeBasisFromJSONTyped,
    ChargeBasisToJSON,
} from './ChargeBasis';
import type { ChargeFrequency } from './ChargeFrequency';
import {
    ChargeFrequencyFromJSON,
    ChargeFrequencyFromJSONTyped,
    ChargeFrequencyToJSON,
} from './ChargeFrequency';

/**
 * 
 * @export
 * @interface ChargeItem
 */
export interface ChargeItem {
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    acId: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    acName: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    bcId: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    bcName: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    blId: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    blName: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    buId: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    buName: string;
    /**
     * 
     * @type {number}
     * @memberof ChargeItem
     */
    chAmount: number;
    /**
     * 
     * @type {ChargeBasis}
     * @memberof ChargeItem
     */
    chBasis: ChargeBasis;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    chBegin: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    chEnd: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    chBilledTo: string;
    /**
     * 
     * @type {ChargeFrequency}
     * @memberof ChargeItem
     */
    chFrequency: ChargeFrequency;
    /**
     * 
     * @type {boolean}
     * @memberof ChargeItem
     */
    chIncTax: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    chInvoicedTo: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChargeItem
     */
    chIsClosed: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    chStatus: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    chType: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    entity: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    itId: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    itName: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    lcId: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    lcName: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    ocId: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    ocName: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    spId: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeItem
     */
    spName: string;
}


/**
 * Check if a given object implements the ChargeItem interface.
 */
export function instanceOfChargeItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "acId" in value;
    isInstance = isInstance && "acName" in value;
    isInstance = isInstance && "bcId" in value;
    isInstance = isInstance && "bcName" in value;
    isInstance = isInstance && "blId" in value;
    isInstance = isInstance && "blName" in value;
    isInstance = isInstance && "buId" in value;
    isInstance = isInstance && "buName" in value;
    isInstance = isInstance && "chAmount" in value;
    isInstance = isInstance && "chBasis" in value;
    isInstance = isInstance && "chBegin" in value;
    isInstance = isInstance && "chEnd" in value;
    isInstance = isInstance && "chBilledTo" in value;
    isInstance = isInstance && "chFrequency" in value;
    isInstance = isInstance && "chIncTax" in value;
    isInstance = isInstance && "chInvoicedTo" in value;
    isInstance = isInstance && "chIsClosed" in value;
    isInstance = isInstance && "chStatus" in value;
    isInstance = isInstance && "chType" in value;
    isInstance = isInstance && "entity" in value;
    isInstance = isInstance && "itId" in value;
    isInstance = isInstance && "itName" in value;
    isInstance = isInstance && "lcId" in value;
    isInstance = isInstance && "lcName" in value;
    isInstance = isInstance && "ocId" in value;
    isInstance = isInstance && "ocName" in value;
    isInstance = isInstance && "spId" in value;
    isInstance = isInstance && "spName" in value;
    return isInstance;
}

export function ChargeItemFromJSON(json: any): ChargeItem {
    return ChargeItemFromJSONTyped(json, false);
}

export function ChargeItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChargeItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'key': json['key'],
        'id': json['id'],
        'acId': json['acId'],
        'acName': json['acName'],
        'bcId': json['bcId'],
        'bcName': json['bcName'],
        'blId': json['blId'],
        'blName': json['blName'],
        'buId': json['buId'],
        'buName': json['buName'],
        'chAmount': json['chAmount'],
        'chBasis': ChargeBasisFromJSON(json['chBasis']),
        'chBegin': json['chBegin'],
        'chEnd': json['chEnd'],
        'chBilledTo': json['chBilledTo'],
        'chFrequency': ChargeFrequencyFromJSON(json['chFrequency']),
        'chIncTax': json['chIncTax'],
        'chInvoicedTo': json['chInvoicedTo'],
        'chIsClosed': json['chIsClosed'],
        'chStatus': json['chStatus'],
        'chType': json['chType'],
        'entity': json['entity'],
        'itId': json['itId'],
        'itName': json['itName'],
        'lcId': json['lcId'],
        'lcName': json['lcName'],
        'ocId': json['ocId'],
        'ocName': json['ocName'],
        'spId': json['spId'],
        'spName': json['spName'],
    };
}

export function ChargeItemToJSON(value?: ChargeItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'key': value.key,
        'id': value.id,
        'acId': value.acId,
        'acName': value.acName,
        'bcId': value.bcId,
        'bcName': value.bcName,
        'blId': value.blId,
        'blName': value.blName,
        'buId': value.buId,
        'buName': value.buName,
        'chAmount': value.chAmount,
        'chBasis': ChargeBasisToJSON(value.chBasis),
        'chBegin': value.chBegin,
        'chEnd': value.chEnd,
        'chBilledTo': value.chBilledTo,
        'chFrequency': ChargeFrequencyToJSON(value.chFrequency),
        'chIncTax': value.chIncTax,
        'chInvoicedTo': value.chInvoicedTo,
        'chIsClosed': value.chIsClosed,
        'chStatus': value.chStatus,
        'chType': value.chType,
        'entity': value.entity,
        'itId': value.itId,
        'itName': value.itName,
        'lcId': value.lcId,
        'lcName': value.lcName,
        'ocId': value.ocId,
        'ocName': value.ocName,
        'spId': value.spId,
        'spName': value.spName,
    };
}

