/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';

/**
 * 
 * @export
 * @enum {string}
 */
export type ChargeFrequency =
  'Daily' | 
  'Calendar Weekly' | 
  'Calendar Monthly' | 
  'Calendar Quarterly' | 
  'Calendar SemiAnnual' | 
  'Calendar Annual' | 
  'Lease Weekly' | 
  'Lease Monthly' | 
  'Lease Quarterly' | 
  'Lease SemiAnnual' | 
  'Lease Annual'




export function ChargeFrequencyFromJSON(json: any): ChargeFrequency {
    return ChargeFrequencyFromJSONTyped(json, false);
}

export function ChargeFrequencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChargeFrequency {
    return json;
}

export function ChargeFrequencyToJSON(value?: ChargeFrequency | null): any {
    return value;
}

